import { useState, useRef, useEffect } from "react"
import { API_CALL } from "../../utilities/function"
import Button from "../../component/Button"
import Card from "../../component/Card"
import Input from "../../component/Input"
import Modal from "./../../component/Modal"
import { Link, useLoaderData } from "react-router-dom"
import Credentials from "./Credentials"
import Authorisation from './Authorisation'
import OTP from "./OTP"
import { Logo, HelpBoxContent } from "../../utilities/constants"
import SecuritiesLogo from "./../../assets/securities.jpg"
import Footer from "../../component/Footer"
import Fade from "../../component/Fade"
import Disclaimer from "../../component/Disclaimer"

const Login = () => {
  const [isOTP, setIsOTP] = useState(false);
  const [isAuthorise, setAuthorize] = useState(false);
  const [requestToken, setRequestToken] = useState(null);
  const [callbackUrl, setCallbackUrl] = useState(null);
  const [termsAndConditions, setTermsAndConditions] = useState(null);
  const [loginID, setLoginID] = useState('');
  const [timer, setTimer] = useState(null);
  const [twoFA, setTwoFA] = useState(null);
  const [checkAuthorize, setCheckAuthorize] = useState(null);
  const [openHelpBox, setOpenHelpBox] = useState(false);
  const [openDisclaimer, setOpenDisclaimer] = useState(false);
  const [params, setParams] = useState(null)
  console.log(params);
  return (
    <>
      {/* Help Box Modal */}
      {openHelpBox && <Modal onClick={() => setOpenHelpBox(false)} className='p-5 lg:w-1/3 '>
        <div className="flex flex-col gap-5">
          <h2 className="text-lg  font-bold text-Dark-Blue-80">
            Trouble Logging in?
          </h2>
          <div className="px-3 py-6">
            <ul className="gap-7 flex flex-col">
              {HelpBoxContent.length > 0 && HelpBoxContent.map((item, index) =>
                <li key={index} className="flex gap-3 cursor-pointer items-center hover:text-Primary-Blue-100">
                  <span className="material-symbols-outlined text-Grey-50"> {item.icon} </span>
                  <Link target="_blank" to={item.link} className="font-semibold ">{item.label}</Link>
                </li>)}
            </ul>
          </div>
        </div>
      </Modal>}

      {/* Authorisation */}
      {

        isAuthorise &&
        <Modal className='lg:bg-transparent max-lg:bg-transparent lg:w-2/5'>
          <Authorisation
            setIsOTP={setIsOTP}
            setOpenDisclaimer={setOpenDisclaimer}
            setParams={setParams}
            termsAndConditions={termsAndConditions}
            requestToken={requestToken}
            callbackUrl={callbackUrl}
            setAuthorize={setAuthorize}
          />
        </Modal>

      }
      {openDisclaimer && <Disclaimer
        callbackUrl={callbackUrl}
        params={params}
        requestToken={requestToken}
        isAuthorize={!checkAuthorize}
        setAuthorize={setAuthorize}
        setOpenDisclaimer={setOpenDisclaimer}
      />}
      <Fade>
        <div className="w-full h-[5vh] max-lg:hidden bg-[#2d4577] flex items-center justify-center">
          <div className="w-[1024px] py-2">
            <img src={SecuritiesLogo} className="w-[135px]" alt="" />
          </div>
        </div>
        <section className='h-[95vh] min-[95vh] max-lg:min-h-screen w-full flex max-lg:items-start justify-center items-center lg:py-10'>
          <div className='w-[1024px] min-h-full flex relative justify-center'>
            {
              <Credentials
                setIsOTP={setIsOTP}
                setLoginID={setLoginID}
                setOpenDisclaimer={setOpenDisclaimer}
                setTwoFA={setTwoFA}
                setParams={setParams}
                setCallbackUrl={setCallbackUrl}
                setCheckAuthorize={setCheckAuthorize}
                setAuthorize={setAuthorize}
                setOpenHelpBox={setOpenHelpBox}
                setRequestToken={setRequestToken}
                setTermsAndConditions={setTermsAndConditions}
                isOTP={isOTP}
              />
            }
            {/* {
            isOTP &&
            <OTP
              timer={timer}
              twoFA={twoFA}
              setTermsAndConditions={setTermsAndConditions}
              setRequestToken={setRequestToken}
              setAuthorize={setAuthorize}
              loginID={loginID}
              setIsOTP={setIsOTP} />
          } */}



          </div>

        </section>

      </Fade>
      <div className="h-full flex items-center justify-center">

      </div>

      <Footer />
    </>
  )
}

export default Login