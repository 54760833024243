// import Logo from './../assets/Logo.svg'
import linkedin from './../assets/linkedin.svg'
import instagram from './../assets/instagram.svg'
import twitter from './../assets/twitter.svg'
import { Name, Logo, footerLinks } from '../utilities/constants'
import axios from 'axios'
import { useState } from 'react'

const Footer = () => {
    const [showLinks, setShowLinks] = useState(Array(footerLinks.length).fill(false));

    const handleMouseEnter = (index) => {
        const updatedShowLinks = [...showLinks];
        updatedShowLinks[index] = true;
        setShowLinks(updatedShowLinks);
    };

    const handleMouseLeave = () => {
        setShowLinks(Array(footerLinks.length).fill(false));
    };

    return (
        <>
            <footer className='bg-[#025296] flex-col w-full  flex items-center justify-center pt-[80px] '>
                <section className='w-[90vw] flex flex-col gap-5 '>
                    <div className='flex gap-7 items-start max-lg:flex-col'>
                        <div className='flex w-2/3 max-lg:w-full items-start gap-5 max-lg:flex-col relative min-h-[45vh]'>
                            <img className='w-[100px]' src={'https://www.hdfcsec.com/hsl.images//headset-icon-201703291303270692239.png'} alt="Logo" />
                            <div className='flex flex-col gap-5 w-full justify-start'>
                                <div className='flex flex-col w-full'>
                                    <h3 className='text-sm text-white  w-full'>CUSTOMER CARE</h3>
                                    <h2 className='text-[36px] text-white font-thin leading-none  w-full' >022-39019400</h2>
                                    <h2 className='text-[36px] text-white font-thin leading-none  w-full' >022-64804444</h2>
                                </div>
                                <div className='flex flex-col w-full'>
                                    <h3 className='text-sm text-white w-full'>CENTRALIZED DEALING DESK</h3>
                                    <h2 className='text-[36px] text-white font-thin leading-none w-full' >022-33553366</h2>
                                    <h2 className='text-[36px] text-white font-thin leading-none w-full' >022-49360600</h2>
                                </div>
                                <div className='w-full flex lg:absolute left-0 bottom-0'>
                                    <img src="https://www.hdfcsec.com/hsl.images//logo-202105061624171814745.png" alt="" />
                                </div>

                            </div>
                        </div>
                        <div className='flex w-full'>
                            <div className='grid max-lg:flex-col max-lg:flex grid-cols-4 gap-5 justify-start '>
                                {footerLinks.map((item, index) => (
                                    <div
                                        key={index}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={handleMouseLeave}
                                        className='text-[16px] flex items-start uppercase  text-white font-medium relative'
                                    >
                                        {item.title}
                                        <span className='material-symbols-outlined text-white cursor-pointer'>
                                            expand_more
                                        </span>
                                        {showLinks[index] && (
                                            <div className='absolute w-[220px] h-[300px]  overflow-y-scroll top-[100%] bg-white p-5 text-black z-10 flex flex-col gap-3'>
                                                {item.links.map((subItem, subIndex) => (
                                                    <a key={subIndex} className='hover:text-blue-600' href={subItem.url}>
                                                        {subItem.title}
                                                    </a>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* <div className='flex justify-center mt-[90px] text-white'>
                        <div className='flex gap-5'>
                            <p className='font-medium text-sm'>{Name} Pvt. Ltd. ©</p>
                            <p className='font-medium text-sm'>Privacy Policy</p>
                            <p className='font-medium text-sm'>Terms of Use</p>
                        </div>
                    </div> */}
                </section>
            </footer>
            <div className="w-full flex items-center justify-center py-10 bg-[#025296] text-white">
                <div id="footer-para" className="w-[90vw]">
                    <p className="text-justify"><span className="text-sm"> <span>Attention Investor,</span> <br /> <span>Prevent unauthorised transactions in your account. Update your mobile numbers/email IDs with us. Receive information of your transactions directly from Stock Exchange / Depositories on your mobile/email at the end of the day.</span> </span></p>
                    <p className="text-justify"><span className="text-sm"> <span> <span>ASBA :- "No need to issue cheques by investors while subscribing to IPO. Just write the bank account number and sign in the application form to authorise your bank to make payment in case of allotment. No worries for refund as the money remains in investors account."</span></span></span></p>
                    <p className="text-justify"><span className="text-sm"><span><span>The securities are quoted as an example and not as a recommendation.</span></span></span></p>
                    <p className="text-justify"><span className="text-sm">Investment in securities market are subject to market risks, read all the related documents carefully before investing.&nbsp;</span><span className="text-sm">Registration granted by SEBI, membership of BASL (in case of IAs) and certification from NISM in no way guarantee performance of the intermediary or provide any assurance of returns to investors.</span></p>
                    <p className="text-justify">&nbsp;</p>
                    <div className="text-justify"><span className="text-sm">HDFC securities Ltd.</span></div>
                    <div className="text-justify"><span className="text-sm">SEBI Registration No.: INZ000186937 (NSE, BSE, MSEI, MCX) |NSE Trading Member Code: 11094 | BSE Clearing Number: 393 | MSEI Trading Member Code: 30000 | MCX Member Code: 56015 | IN-DP-372-2018 (CDSL, NSDL) | CDSL DP ID: 12086700 | NSDL DP ID: IN304279 | AMFI Reg No. ARN -13549 | PFRDA Reg. No - POP 11092018 | IRDA Corporate Agent Licence No.CA0062 | Research Analyst Reg. No. INH000002475 | Investment Adviser: INA000011538-Type-Non Individual , Validity of Registration-Perpetual, Principal Officer&nbsp; Contact Number 022-68494702, Email Id- investmentadvisers@hdfcsec.com&nbsp;| CIN-U67120MH2000PLC152193</span></div>
                    <div className="text-justify"><span className="text-sm">Registered Address: I Think Techno Campus, Building, B, Alpha, Office Floor 8, Near Kanjurmarg Station, Kanjurmarg (East), Mumbai -400 042. Tel -022 30753400. Compliance Officer: Mr. Murli V Karkera. Ph: 022-3045 3600, Email: complianceofficer@hdfcsec.com.</span></div>
                    <div className="text-justify"><br /> <span className="text-sm">KYC is one time exercise while dealing in securities markets - once KYC is done through a SEBI registered intermediary (broker, DP, Mutual Fund etc.), you need not undergo the same process again when you approach another intermediary.</span></div>
                    <div className="text-justify"><span className="text-sm"><br /></span></div>
                    <div className="text-justify"><span className="text-sm">HDFC Securities has a proprietary trading desk. This desk maintains an arm’s length distance with the Research team and all its activities are segregated from Research activities. &nbsp;The proprietary desk operates independently, potentially leading to investment decisions that may deviate from research views.</span></div>
                    <div className="text-justify">&nbsp;</div>
                    <div className="text-justify"><span className="font-size: medium;">HDFC Securities Ltd-AMFI-registered Mutual Fund Distributor</span></div>
                    <div className="text-justify"><br /> <span className="text-sm">Kindly note that as per NSE circulars nos: NSE/INVG/36333 dated November 17, 2018, NSE/INVG/37765 dated May 15.2018 and BSE circular nos: 20171117-18 dated November 17, 2018, 20180515-39 dated May 15.2018, trading in securities in which unsolicited messages are being circulated is restricted. The list of such stocks are available on the website of NSE &amp; BSE. Investors are advised not to blindly follow the unfounded rumours, Tips given in social networks, SMS, WhatsApp, Blogs etc. and invest only after conducting appropriate analysis of respective companies.</span></div>
                    <div className="text-justify">
                        <p><span className="text-sm">Insurance is not a Exchange traded product and the Member is just acting as distributor. All disputes related to the distribution activity of insurance will not have access to Exchange investor redressal forum or Arbitration mechanism.</span></p>
                        <p><span className="font-size: medium;">Educative material to become an informed investor.</span></p>
                        <p><span className="font-size: medium;"><span><span className="text-sm">Dear Investor,</span></span></span></p>
                        <p><span className="font-size: medium;"><span><span className="text-sm">As you are aware, under the rapidly evolving dynamics of financial markets, it is crucial for investors to remain updated and well-informed about various aspects of investing in securities market. In this connection, please find a link to the BSE Investor Protection Fund website where you will find some useful educative material in the form of text and videos, so as to become an informed investor.</span><br /></span><span className="text-sm"><a className='cursor-pointer' href="https://www.bseipf.com/investors_education.html" rel="noopener noreferrer" target="_blank" data-auth="NotApplicable" data-linkindex="0" data-ogsc="" >https://www.bseipf.com/investors_education.html</a><br /></span><br /><span>We believe that an educated investor is a protected investor !!!</span></span></p>
                    </div>
                    <div className="text-justify"><br /> <span className="text-sm">Site best viewed in IE 11.0+, Google Chrome and Mozila Firefox 3.0+ at 1024 x 768 pixels resolution.</span></div> </div >
            </div >
        </>
    )
}

export default Footer