import { NavbarLogo } from '../utilities/constants'
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import HamburgerIcon from './../assets/Hamburger.svg';
import HamburgerWithAvatar from './../assets/hamburger_with_avatar.svg'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Avatar from './../assets/avatar.svg';
import Card from './Card';
import Button from './Button';
import { toast } from 'react-toastify';

export const checkActive = ({ isActive, isPending }) =>
  `${isPending ? "pending" : isActive ? "text-Primary-Blue-100" : "text-Dark-Blue-60"} cursor-pointer font-medium text-base  hover:text-Primary-Blue-100 hover:font-semibold`

export const redirectTo = () => {
  window.location.href = '/ir-docs'
}

export const HamburgerMenu = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const logout = () => {
    localStorage.clear()
    if (window.location.pathname === "/oapi/v1/dashboard/apps") {
      window.location.href = "/oapi/v1/im/login";
    } else {
      window.location.reload()
    }
  }
  useEffect(() => {
    if (localStorage.imIsLoggedIn) {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }, [])

  return (
    <div className='flex flex-col bg-white z-[99] p-5 right-0 w-full top-[9vh]  shadow-md absolute'>
      <div className='flex flex-col gap-12 items-center'>
        <NavLink
          to="/"
          className={checkActive}
        >
          Home
        </NavLink>
        <NavLink
          to="/oapi/connected-apps"
          className={checkActive}
        >
          Connected Apps
        </NavLink>
        <span
          onClick={redirectTo}
          className={'cursor-pointer font-medium text-Dark-Blue-60 text-base  hover:text-Primary-Blue-100 hover:font-semibold'}>
          API Docs
        </span>

        {isLoggedIn && <NavLink
          to='/oapi/v1/dashboard/apps'
          className={checkActive}
        >
          My Apps
        </NavLink>}

        {isLoggedIn &&
          <span
            onClick={logout}
            className={'cursor-pointer font-medium text-Dark-Blue-60 text-base  hover:text-Primary-Blue-100 hover:font-semibold'}>
            Logout
          </span>}
      </div>
    </div>
  )
}

const Navbar = ({ isLogin, funcOpenHamburgerMenu }) => {

  const [isLogout, setIsLogout] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (localStorage.imIsLoggedIn) {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }, [])

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 })
  const navigate = useNavigate();
  const Links = ({ name, to, isActive }) => {
    const handleClick = () => {
      window.location = to
    }
    return (
      <div onClick={handleClick} className={`${isActive ? 'text-Primary-Blue-100' : 'text-Dark-Blue-60'} cursor-pointer font-medium text-base  hover:text-Primary-Blue-100 hover:font-semibold`}>
        {name}
      </div>
    )
  }
  const logout = () => {
    localStorage.clear()
    isLogin = false;
    setOpenMenu(false);
    setIsLogout((item) => item + 1)
    toast.success("You've been Logged Out")
    // console.log(window.location)
    if (window.location.pathname === "/oapi/v1/dashboard/apps") {
      window.location.href = "/oapi/v1/im/login";
    } else {
      window.location.reload()
    }
  }

  const [openMenu, setOpenMenu] = useState(false);
  useEffect(() => {
    setIsLogout(0)

  }, [isLogout])

  const Hamburger = () => {
    return (
      <div
        onClick={funcOpenHamburgerMenu}
        className='p-3 cursor-pointer relative'>
        <img src={isLogin ? HamburgerWithAvatar : HamburgerIcon} />
      </div>
    )
  }





  return (
    <div className={`Navbar max-lg:px-3 z-50 ${(isLogin && isTabletOrMobile) ? 'relative' : 'absolute'} top-0 bg-white w-full flex justify-center items-center`}>
      <div className='lg:w-[1140px] max-lg:w-full lg:min-h-[75px] max-lg:h-[10vh] flex  items-center justify-between'>
        <img
          className='cursor-pointer'
          onClick={() => { window.location.href = '/' }}
          src={NavbarLogo} alt="Open-API-Logo"
        />
        {/* <NavbarLogo/> */}
        {!isTabletOrMobile &&
          <div className='flex gap-12 items-center'>
            <NavLink
              to="/"
              className={checkActive}
            >
              Home
            </NavLink>
            <NavLink
              to="/oapi/connected-apps"
              className={checkActive}
            >
              Connected Apps
            </NavLink>
            <span
              onClick={redirectTo}
              className={'cursor-pointer font-medium text-Dark-Blue-60 text-base  hover:text-Primary-Blue-100 hover:font-semibold'}>
              API Docs
            </span>
            {
              isLogin && (
                <>
                  <NavLink
                    to='/oapi/v1/dashboard/apps'
                    className={checkActive}
                  >
                    My Apps
                  </NavLink>

                  {/* <NavLink
                    to='/dashboard/faq'
                    className={checkActive}
                  >
                    FAQ
                  </NavLink>
                  <NavLink
                    to='/dashboard/forum'
                    className={checkActive}
                  >
                    Forum
                  </NavLink> */}
                  <div className='relative'>
                    <img
                      onClick={() => setOpenMenu(!openMenu)}
                      src={Avatar}
                      alt='avatar'
                    />
                    {openMenu &&
                      <Card className='absolute shadow-md w-[200px] right-0'>
                        <p
                          onClick={logout}
                          className='cursor-pointer font-semibold hover:text-Primary-Blue-100'>
                          Logout
                        </p>
                      </Card>}
                  </div>
                </>
              )
            }
            {!isLogin && (<Button
              onClick={() => { window.location.href = '/oapi/v1/im/login' }}
              text={'Login '}
              className='bg-Primary-Blue-100 text-White text-base rounded-full px-5' />)
            }
          </div>

        }
        {(isTabletOrMobile) &&
          <div className='flex gap-2 items-center justify-end'>
            {!isLoggedIn && <span
              onClick={() => { window.location.href = '/oapi/v1/im/login' }}
              className='cursor-pointer font-bold text-Primary-Blue-100'>Login</span>}
            <Hamburger />
          </div>}
      </div>

    </div>
  )
}

export default Navbar